import React, { useEffect, useState} from 'react';
import {TabulatorFull as Tabulator} from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

import { axiosService } from '@/_services/account.axios';
import Wait from '@/components/Wait';
import TitreH2 from '@/components/UI/titres/TitreH2';
import ListesDiffusion from './ListesDiffusion';

const ListeMails = () => {

  const [initialValues, setInitialValues] = useState({    
    flagButton: false,  //flag pour affichage boutons après reponse API
    OKMail: false,      //flag pour l'envoi d'un mail
})


  //initialisation du tableau////////////////////////////////
  useEffect(() => {
    if(initialValues.flagButton === false){

    //initialisation options requète API
    const options = {
        values: {
            domaine:'mail',
            action:'listeMails',
        },
        OKData: (data) => {     //action callback déclanchée si tout est OK 
          setInitialValues({
            flagButton:true,
            OKMail: data.OKMail
          });
          afficheTableau(data.listeMails);
        }
    }

    //lancement requète API
    axiosService.postAxios(options);
  }

    return;

  },[])

  // formatter pour colonne

  //destinataires
  const destinatairesFormatter = function(){
    return '<i class="fas fa-user" style="color: #5f56c5;"></i>'
  }
  //popup pour la liste des destinataires
  const destinatairesPopupFormatter = (e,row,onRendered) =>{
    const destinataires= row.getData().destinataires;
    var destListe= '';
    for(let destinataire of destinataires){
      destListe+= destinataire.destinataire + ' ; '
    }
    const container= document.createElement('div');
    container.innerHTML= destListe;
  
    return container;
  }

  //fichiers joints
  const fichiersJointsFormatter = function(cell,formatterParams,onRendered){
    if(cell.getValue())  return '<i class="fas fa-file" style="color: #5f56c5;"></i>'
  }
  //popup pour fichiers joints
  const fichiersJointsPopupFormatter = (e,row,onrenderer) =>{
    const fichiersJoints= row.getData().fichiersJoints.split(';');
    console.log(fichiersJoints);
    let liste=' ';
    const container= document.createElement('div');

    for(const fichierJoint of fichiersJoints){
      liste += fichierJoint + '<br/>';
    }

    container.innerHTML= liste;

    return container;
  }

  //voir message
  const contenuFormatter = function(){
    return '<i class="fas fa-eye" style="color: #5f56c5;"></i>'

  }
  //popup pour le contenu du mesage
  const contenuPopupFormatter =function(e,row,onRendered){
    const data= row.getData();
    const container= document.createElement('div');
    container.innerHTML = data.contenu;

    return container;
  }

  //message en ano
  const destinatairesAnoFormatter = function(cell,formatterParams,onRendered){
    if(cell.getValue())  return '<i class="fas fa-exclamation-triangle" style="color: #B22222;"></i>'
  }
  //popup pour les messages en ano
  const destinatairesAnosPopupFormatter = function(e,row,onRendered){
    const destinatairesAno= row.getData().destinatairesAno;
    const container= document.createElement('div');
    let liste= ' ';
    for(const destinataireAno of destinatairesAno){
      liste += destinataireAno.destinataire + '-' + destinataireAno.messageErreur + '</br>';
    }

    container.innerHTML = liste;

    return container;

  }
  
  const afficheTableau = (data) => {
    const tabulator = new Tabulator('#listeMails',{
      
      data:data,

      columnDefaults:{
      width:120
      },      

      columns: [
          {title: "date d'envoi", field: 'dateDiffusion', hozAlign:'center',
            formatter:"datetime", formatterParams:{
              inputFormat:"yyyy-mm-dd",
              outputFormat:"dd/mm/yyyy",
              invalidPlaceholder:"(date absente)",
              timezone:"default",
              }
          },
          {title: "sujet", field: 'sujet', width:150},
          {title: "Emetteur", field: "emetteur", width: 180},
          {formatter: contenuFormatter, field: 'contenu', width:50, hozAlign:'center',vertAlign:'center', clickPopup:contenuPopupFormatter},
          {formatter:destinatairesFormatter, width:50, hozAlign:'center',vertAlign:'center', clickPopup: destinatairesPopupFormatter},
          {formatter:fichiersJointsFormatter, field: 'fichiersJoints', width:50, hozAlign:'center',vertAlign:'center', clickPopup: fichiersJointsPopupFormatter},
          {formatter:destinatairesAnoFormatter, field: 'destinatairesAno', width:50, hozAlign:'center',vertAlign:'center', clickPopup: destinatairesAnosPopupFormatter},
      ],
      pagination:true,
      paginationSize:10,
      paginationSizeSelector:false, //enable page size select element and generate list options

      locale:true,
      langs:{
        "default":{
            "pagination":{
                "first":"1ere", //text for the first page button
                "last":"Derniere",
                "prev":"Preced",
                "next":"Suivante",
            },
        }
      },

    })
  }

  const nouveauMailClick = ()=>{
      window.location.href='/messagerie/mailing';
    }


  const divStyle={
      padding:'50px',
      margin:'20px'
    }

  return(
      <div>
        {
          !initialValues.flagButton &&
          <Wait />
        }
        {initialValues.flagButton &&
          <div style={divStyle}>
            <TitreH2 titre='Liste des mails envoyés' />
            {
              initialValues.OKMail &&
              <div className='row'>
                <button className='col-2 offset-2 button mb-3' onClick={nouveauMailClick} >Nouveau mail</button>
                <span className='col-3 offset-5 fs-5'>Listes de diffusion</span>
              </div>
            }
            <div className='row'>
              <div className='col-7 ' id='listeMails' /> 
              <div className='offset-7 col-3 ms-5' ><ListesDiffusion /></div>
            </div>
          </div>
        }
      </div>
  )
}


export default ListeMails;