import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router';

import {TabulatorFull as Tabulator} from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

import { axiosService } from '@/_services/account.axios';

const ListesDiffusion = () =>{

  const navigate = useNavigate();

    const [initialValue, setInitialValues] = useState({    
        flagButton: false,  //flag pour affichage boutons après reponse API
    })
    
    
    //initialisation du tableau////////////////////////////////
    useEffect(() => {
        if(initialValue.flagButton === false){
    
        //initialisation options requète API
        const options = {
            values: {
                domaine:'mail',
                action:'getListesDiffusion',
            },
            OKData: (data) => {     //action callback déclanchée si tout est OK 
              setInitialValues({flagButton:true});
              console.log(data)
              afficheTableau(data);
            }
        }
    
        //lancement requète API
        axiosService.postAxios(options);
      }
    
        return;
    
    },[])

    //////////// tableau des listes de diffusion

    const destinatairesFormatter = function(){
        return '<i class="fas fa-user" style="color: #5f56c5;"></i>'
      }
    
        //popup pour la liste des destinataires
    const destinatairesPopupFormatter = (e,row,onRendered) =>{
        const destinataires= row.getData().destinataires;
        var destListe= '';
        for(let destinataire of destinataires){
        destListe+= destinataire.destinataire + ' ; '
        }
        const container= document.createElement('div');
        container.innerHTML= destListe;
        return container;
    }

    const majListeFormatter = (cell,formatterParams, onRendered) =>{
       
        return cell.getValue() ?'<i class="fas fa-pen" style="color: #5f56c5;"></i>' : '';
    }

    const delListeFormatter = (cell,formatterParams, onRendered) =>{
      return cell.getValue() ?'<i class="fas fa-trash" style="color: #5f56c5;"></i>' : '';
    }

    const delListeDiffusion = (idliste) =>{
      const options= {
        values:{
          domaine: 'mail',
          action: 'delListeDiffusion',
          idListe: idliste,  
        },
        OKData: (data)=>{
          navigate('/AR/delListeDiffusion');
        }          
      }

      axiosService.postAxios(options);

    }
    const afficheTableau= (data) =>{
        const tabulator = new Tabulator('#listesDiffusion', {
            
            data: data,

            columns: [
                {title: "idListe", field: 'idliste', visible: false},
                {title:"Libell&eacute;", field:"libelleListe",width:135, hozAlign: 'center'},
                {formatter:destinatairesFormatter, width:50, hozAlign:'center',vertAlign:'center', clickPopup: destinatairesPopupFormatter},
                {field: "editable", formatter:majListeFormatter,width:40,hozAlign:'center', cellClick:function(e,cell){if(cell.getValue())window.location.href='/messagerie/majListeDiffusion/' 
                + cell.getRow().getData().idListe 
                + '/' + cell.getRow().getData().libelleListe}},
                {field: "supprimable", formatter:delListeFormatter,width:40,hozAlign:'center', cellClick:function(e,cell){
                      // eslint-disable-next-line no-restricted-globals
                      if(cell.getValue() && confirm('Voulez-vous supprimer la liste '+ cell.getRow().getData().libelleListe + '?')){
                        delListeDiffusion(cell.getRow().getData().idListe );
                      }
                  },
                }
            ],
        
            pagination:false,
      
            locale:true,
            langs:{
              "default":{
                  "pagination":{
                      "first":"1ere", //text for the first page button
                      "last":"Derniere",
                      "prev":"Preced",
                      "next":"Suivante",
                  },
              }
            },
      });
    }    

    const nouvelleListe = ()=>{
      navigate('/messagerie/addListeDiffusion')
    }

    return(
        <div >
          <button className='button  mb-3' onClick={nouvelleListe}>Créer une liste</button>
          <div id='listesDiffusion' />
        </div>
    )
  
}

export default ListesDiffusion;