import React from 'react';
import {Routes, Route} from "react-router-dom";

import Error from '@/components/errors/Error';

import Login from './Login';
import Logout from './Logout';
import ChangeMdp from './ChangeMdp';
import MdpOublie from './MdpOublie';

const LoginRouter = ()=>{
    return (
        <Routes>
            <Route path='' element={<Login />} />
            <Route path='login' element={<Login />} />
            <Route path='logout' element={<Logout />} />
            <Route path='changeMdp' element={<ChangeMdp />} />
            <Route path='mdpOublie' element={<MdpOublie />} />
            <Route path='*' element={<Error type='404'>la page n'existe pas</Error>} />
        </Routes>
    )
    
}

export default LoginRouter;