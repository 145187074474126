import React, { useEffect, useRef, useState} from 'react';

import { accountService } from '@/_services/account.service';
import { axiosService } from '@/_services/account.axios';


const NavBar = () => {


    //valeurs par défaut pour le formulaire
    const [initialValues, setInitialValues] = useState({
        email:'',
        pages: []
    })

    const flag = useRef(false);     //flag pour synchroniser la requète axios

    //initialisation si connecté
        useEffect(() => {
            if(accountService.isLogged()){
                if(flag.current === false){
    
                //initialisation options requète API
                const options = {
                    values: {
                        domaine:'front',
                        action:'userPages',
                    },
                    OKData: (data) => {     //action callback déclanchée si tout est OK 
                        const initials={...initialValues, ...data}
                        setInitialValues(initials)
                        accountService.setEmailUser(data.email);
                        accountService.setRoleUser(data.role);
                    }
                }
    
                //lancement requète API
                axiosService.postAxios(options);
            }
    
            return ()  => flag.current = true;
            }
    
        },[initialValues])
    
    return (
        <div>
          {               
            accountService.isLogged() ?
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  {initialValues.pages.map((item, index) => (
                    <li class="nav-item">
                      <a class="nav-link active" href={'/' + item.page}>{item.libPage}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
          : ''
        }
        
      </div>
    );
};

export default NavBar;