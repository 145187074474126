import React from 'react';
import bootstrap from 'bootstrap';

import './test.css';

const Test = () => {
    return (
        <div className='container'>
        <div className='row'>
        <div className='col-md-2' />
            <div className='col-md-10 bordureRouge col-md-offset-1 '>
                cocou
            </div>
        </div>
        <div className='row'>
            <div className='col-md-6 bordureVerte col-md-offset-3'>
                cocou
            </div>
        </div>
        </div>
    );
};

export default Test;