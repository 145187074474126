import React from 'react';

const Footer = () => {
    return (
        <footer style={{backgroundColor:'lightcyan'}}>
            <div className='text-black text-center md-4'>
                <h3>Soleil du Loir</h3>
                <p>Association loi 1901</p>
            </div>
        </footer>
    );
};

export default Footer;