import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import './AR.css';
import { accountService } from '../../../_services/account.service';

const AR_router = () => {
    const {ar} = useParams();

    const navigate = useNavigate();
    let urlRetour= '/';     //URL par défaut pour le bouton retour
    let libRetour= 'Retour à l\'accueil';       //Libellé par défaut vers la page d'accueil
    
    return (
        <div className='message'>
            {ar==='adhesion' && !accountService.isLogged() &&
                <>
                    <h3>Votre demande d'adhésion a été enregistrée</h3>
                    {/* <p>Un mail de confirmation vous a été envoyé, surveillez bien vos spam</p> */}
                    <button onClick={()=>window.location.assign(window.routes.URLFront)}>{libRetour}</button>             
                </>
            }
            {ar==='adhesion' && accountService.isLogged() &&
                <>
                    <h3>Les coordonnées de l'adhérent on été enregistrées</h3>
                        <button onClick={()=>navigate('/adherents')}>Retour à la liste</button>
                </>
                
            }
            {ar==='majAdhesion' &&
                <>
                    <h3>Les coordonnées de l'adhérent on été modifiées</h3>
                    <button onClick={()=>navigate('/adherents')}>Retour à la liste</button>
                </>
            }
            {ar==='mdpOublie' &&
                <>
                    <h3>Un mail vient de vous être envoyé</h3>
                    <p>Il contient un mot de passe provisoire que vous devrez utiliser pour vous connecter la prochaine fois.</p>
                    <p>Vous devrez alors enregistrer un nouveau mot de passe</p>
                    <button onClick={()=>navigate(urlRetour)}>{libRetour}</button>
                </>
            }
            {ar==='changeMdp'  &&
                <>
                    <h3>Votre mot de passe a été modifié</h3>
                    <button onClick={()=>navigate(urlRetour)}>{libRetour}</button>
                </>
            }
            {ar==='connectExpire' &&
                <>
                    <h3>Votre connection est expirée.</h3>
                    <i>Veuillez vous reconnecter</i>
                    <button onClick={()=>navigate(urlRetour)}>{libRetour}</button>
                </>

            }
            {ar==='envoiMail'&&
                <>
                    <h3>Le mail a été envoyé </h3>
                    <button onClick={()=>navigate('/messagerie')}>{'Retour à la messagerie'}</button>
                </>

            }
            {ar==='errorMail'&&
            <>
                    <h3>Le mail a été envoyé mais il y a eu des erreurs contacter le webmaster</h3>
                    <button onClick={()=>navigate('/messagerie')}>{'Retour à la messagerie'}</button>
                </>
            }
            {ar==='majListeDiffusion'&&
                <>
                    <h3>La liste a été modifiée</h3>
                    <button onClick={()=>navigate('/messagerie')}>Retour à la liste</button>
                </>

            }
            {ar==='delListeDiffusion'&&
                <>
                    <h3>La liste a été supprimée</h3>
                    <button onClick={()=>navigate('/messagerie')}>Retour à la liste</button>
                </>

            }
        </div>
    );
};

export default AR_router;