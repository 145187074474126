import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TabulatorFull as Tabulator} from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

import { accountService } from '@/_services/account.service';
import { axiosService } from '@/_services/account.axios';
import TitreH2 from '@/components/UI/titres/TitreH2';
import './mail.css';

const Mailing = () => {

  let span = document.getElementById("message");  //pour affichage messages d'anomalie

  const navigate= useNavigate();

  const [mailInfo,setMailInfo]= useState({
    idListeDiffusion: '',
    sujet: '',
    entete: '',
    signature: '',
    listesDiffusion: '',
    fichiersJoints:'',
  });

  
  const editorRef = useRef(null);

  const [flagMail,setFlagMail] = useState('mail');

  const [messageAR,setMessageAR] = useState('');

  const editorValue = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  //récupération de la signature et des listes de diffusion
  const flag= useRef(false); //pour synchro axios
  const listesDiffusion= useRef('');

  useEffect(() => {
    if(flag.current === false){
      const options= {
        values:{
          domaine:'mail',
          action: 'getDataMail',
          email: accountService.getEmailUser(),  
        },
        OKData: (data) => {
          listesDiffusion.current= data.listesDiffusion;
          setMailInfo({signature: data.signature})
          console.log(listesDiffusion)
        }
      }
      axiosService.postAxios(options);


    }
 
  }, [])

const handleChange= (e)=>{
    setMailInfo({...mailInfo,[e.target.name]: e.target.value})
    span && span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
  }

    
  const onSubmit= (e)=>{
    e.preventDefault();
    
     //controles
    let textAno='';
    if(!mailInfo.idListeDiffusion ){
      textAno+= 'Liste de diffusion obligatoire ';
    }
    if(!mailInfo.sujet){
      textAno+= 'Sujet obligatoire ';
    }
    if(!mailInfo.entete){
      textAno+= 'En tête obligatoire ';
    }
    if(editorRef.current.getContent()===''){
      textAno+= ' Absence de contenu '
    }
    let txt = document.createTextNode(textAno);
    span.appendChild(txt);

    //Si pas d'ano envoi mail
    if(textAno===''){
      setFlagMail('wait');
      let corpsMessage= mailInfo.entete !== 'NA'?mailInfo.entete +editorRef.current.getContent()
                                                :editorRef.current.getContent();

      const options={
        values:{
          domaine: 'mail',
          action: 'envoiMail',
          mode: 'differe',
          sujet: mailInfo.sujet,
          idListeDiffusion: mailInfo.idListeDiffusion,
          corpsMessage: corpsMessage,
          emailEmetteur: accountService.getEmailUser(),
          fichiersJoints: fichiersJoints
        },
        OKData: (data)=>{
          //console.log(data)
          setFlagMail('AR')
          setMessageAR('Votre mail a été mis dans la file d\'attente, L sera envoyé dans l\'heure qui suit.')
        },
        ErrorData: (data)=>{
          setFlagMail('AR');
          console.log(data)
          setMessageAR('Le mail n\'a pu être envoyé');
        }
      }

      axiosService.postAxios(options);

    }
  };

  let dataFich=[];          //variable de contenu de tableau
  let fichiersJoints=[];    //variable des fichiers joints pour attachement dans mail

  // Ajout de fichiers dans tableau
  const fileChange= ()=>{
    const fichiers= document.getElementById('fichiersJoints').files;
    for( const fichier of fichiers) {
      fichiersJoints.push(fichier);
      dataFich.push({nomFichier:fichier.name})
    }
    console.log(fichiersJoints)
  }

  
  const removeFichierFormatter = ()=>{
    return '<i class="fas fa-trash" style="color: #5f56c5;"></i>'
  }

  //supression fichier joints
  const removeFichier= (e,cell,onrenderer) => {
    //suppression dans files[]
    const fichiers= document.getElementById('fichiersJoints').files;
    console.log(fichiers)
    const nomfichier= cell.getRow().getData().nomFichier;
    fichiersJoints= [];
    for(let fichier of fichiers){
      if(fichier.name!==nomfichier){
        fichiersJoints.push(fichier)
      } 
    }
    console.log(fichiersJoints);

    //suppression ligne dans tables
    cell.getRow().delete();
  }
  
  const listePiecesJointes= new Tabulator('#listePiecesJointes',{
  reactiveData:true,
  data: dataFich,
  columns: [
              {title: 'Nom du fichier', field: 'nomFichier', width:250},
              {formatter: removeFichierFormatter, width:40, hozAlign:'center', cellClick:removeFichier},
            ]
  })

  return (

    <>
    {
      flagMail === 'mail' &&
        <>
        <TitreH2 titre= 'Envoyer un mail'/>

        <form  data-form-type="other" encType='multipart/formdata'>
            <div className='mb-2'>
                <span className='col-10 offset-2 text-danger ms-1' id='message' name='message'></span>
            </div>

            <div className='row lh-1' >
              <label for='sujet' className="col-1 ms-5">Sujet</label>
              <input id='sujet' name='sujet' className='col-4' value={mailInfo.sujet} onChange={(e)=> handleChange(e)}/>
              <label htmlFor='liste' className='col-1 ms-3'>Liste de diffusion</label>            
              <select name='idListeDiffusion' id='idListeDiffusion' className='col-2' onChange={(e)=> handleChange(e)} >
                  <option></option>
                  {
                    Object.values(listesDiffusion.current).map((liste)=>(
                      liste.idListe!== 3 &&
                      <option value={liste.idListe}>{liste.libelleListe}</option>
                    ))
                  }
                  {localStorage.getItem('emailUser') ==='clmassart@hotmail.com' &&
                      <option value={3}>Test</option> 
                    }
              </select>
              <label for='entete'  className='col-1 ms-3'>Formule politesse</label>
                <select name='entete' id='entete' className='col-2' onChange={(e)=> handleChange(e)} >
                  <option></option>
                  <option value='NA'>Pas de formule</option>
                  <option value='Bonjour %prenom% %nom%'>Bonjour jean Dupont</option>
                  <option value='Bonjour %prenom%'>Bonjour Jean</option>
                </select>        
            </div>
            

            <div className="row">
              <h4 className='col-6 offset-1 text-center mt-5'>Texte du message</h4>
              <h4 className='col-5 text-center mt-5'>Pièces jointes</h4>
            </div>
            <div className='row'          >
              <div className="col-6 offset-1  text-center">
                  <Editor
                      apiKey='barvl4zof939ubm0yyk1a6aizgfjvx5lfdel5dc7ode3vwrx'
                      onInit={(evt, editor) => editorRef.current = editor}
                      initialValue=" "
                      init={{
                      height: 400,
                      // width: 800,
                      menubar: false,
                      branding: false,
                      statusbar: false,
                      plugins: [
                          'lists advlist autolink link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | insertfile',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                  />
              </div>
              <div className='col-5 '>
                  <div className='row'>
                    <label className='col-5 ' for='fichiersJoints'>Ajouter une pièce jointe</label>
                    <input className='col-7 ' type='file' id='fichiersJoints' accept='.pdf,images/*' name='fichiersJoints[]' multiple='multiple' 
                      onChange={(e)=> fileChange(e)}
                    />
                  </div>
                  <div className='row mt-5'>
                    <div className='col-7 offset-1' id='listePiecesJointes'></div>
                  </div>
              </div>
            </div>
            <div className="row" >
                      <button type="submit" className="button col-2 offset-1 mt-3" onClick={onSubmit}>Envoyez</button>
            </div>
        </form>
        </>
    }
    {
      flagMail=== 'wait' &&
      <div className='col-10 offset-6 ' style={{color: '#5f56c5'}}>
        <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
        <h4>Envoi en cours</h4>
      </div>
    }
    {
      flagMail=== 'AR' &&
      <div className='message'>
        <h3>Votre message a été placé en file d'attente</h3>
        <em>Il sera envoyé dans l'heure qui suit</em><br/>
        <button style={{marginTop: '15px'}} onClick={()=>navigate('/messagerie')}>{'Retour à la messagerie'}</button>
      </div>
    }
    </>
  )
};

export default Mailing;