import React, { useEffect, useState} from 'react';
import {TabulatorFull as Tabulator} from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

import { axiosService } from '@/_services/account.axios';
import Wait from '@/components/Wait';
import TitreH2 from '@/components/UI/titres/TitreH2';

const Adherents = () => {

    const [initialValue, setInitialValues] = useState({    
        flagButton: false,  //flag pour affichage boutons après reponse API
        nbAdherents: 0,
        nbValides: 0,
        userCanMajAdh: false,
        userCanValidAdh: false,
    })
    
    
    //initialisation du tableau////////////////////////////////
    useEffect(() => {
        if(initialValue.flagButton === false){

            //initialisation options requète API
            const options = {
                values: {
                    domaine:'front',
                    action:'listeAdherents',
                },
                OKData: (data) => {     //action callback déclanchée si tout est OK 
                    setInitialValues({
                        flagButton:true,
                        nbAdherents: data.nbAdherents,
                        nbValides: data.nbValides,
                        userCanMajAdh: data.userCanMajAdh,
                        userCanValidAdh: data.userCanValidAdh
                    });
                    afficheTableau(data);
                }
            }

            //lancement requète API
            axiosService.postAxios(options);
        }

        return;

    },[])

    // tableau des adhérents ///////////////////////////////////////
    const boutonClick = ()=>{
        window.location.href='/adherents/adhesion';
      }
    
    const divStyle={
        'justify-content':'center',
        padding:'50px',
        width:'min-content'
      }
    
    const boutonStyle={
        with:'25px',
        'margin-bottom':'25px'
      }
      const majAdhFormatter = () =>{
        return '<i class="fas fa-pen" style="color: #5f56c5;"></i>'
      }
    
    const majValidFormatter = cell => {
        return cell.getRow().getData().situation!=='validee'?"<i class='fas fa-coins'></i>":""
      }
   
    const afficheTableau = (data)=>{
        console.log('userCan ' + data.userCanMajAdh);
        
        const listeAdherents = new Tabulator('#listeAdherents',{
            
            data:data.adherents,
            columnDefaults:{
            width:120,
            headerHozAlign:'center',
            },

            rowFormatter:function(row){
                row.getElement().classList.add("table-striped"); 
            },

            columns:[
            {title:"Nom", field:"nom"},
            {title:"Pr&eacute;nom", field:"prenom"},
            {title:"Date Naissance", field:"dateNaissance",
            formatter:"datetime", formatterParams:{
                inputFormat:"yyyy-mm-dd",
                outputFormat:"dd/mm/yyyy",
                invalidPlaceholder:"(date absente)",
                timezone:"default",
            }},          
            {title:"Adresse", field:"adresse", width: 160},
            {title:'Code Postal', field:'codePostal', width:80, hozAlign:'center'},
            {title:'Ville', field:'ville'},
            {title:'Email',
                columns:[
                {field:'email',width:200},
                {field: 'emailValide', formatter: 'tickCross', width: 40}
                ]
            },
            {title:'T&eacute;l&eacute;phone', field:'telephone'},
            {formatter:majAdhFormatter,width:40,hozAlign:'center', cellClick:function(e,cell){if(data.userCanMajAdh) window.location.href='/adherents/majAdhesion/' + cell.getRow().getData().idAdherent}},
            {field: 'situation', formatter:majValidFormatter, width:40,hozAlign:'center', cellClick:function(e,cell){if(cell.getRow().getData().situation!=='validee' && data.userCanValidAdh) window.location.href='/adherents/validAdhesion/' 
                + cell.getRow().getData().idAdherent
                +'/' + cell.getRow().getData().nom
                +'/' + cell.getRow().getData().prenom
            }}
            ],

            pagination:true,
            paginationSize:10,
            paginationSizeSelector:false, //enable page size select element and generate list options

            locale:true,
            langs:{
            "default":{
                "pagination":{
                "first":"1ere", //text for the first page button
                "last":"Derniere",
                "prev":"Preced.",
                "next":"Suivante",
                },
            }
            },
        });
    }


    return (
        <>
            {
                !initialValue.flagButton 
                ?
                <Wait />
                :
                initialValue.flagButton &&
                <div style={divStyle}>
                    <TitreH2 titre='Liste des adhérents' />
                    <div className='ro<'>
                        <button className='col-1' onClick={boutonClick} style={boutonStyle}>Ajouter</button>
                        <span className='col-5 offset-1 fst-italic fs-6 text-info'>
                            Nombre d'adhérents : {initialValue.nbAdherents} dont {initialValue.nbValides} à jour de leur cotisation
                        </span>
                    </div>
                    <div id='listeAdherents'></div>
                </div>
            }
        </>
    );
};

export default Adherents;