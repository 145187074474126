import React, { useEffect, useState} from 'react';
import {TabulatorFull as Tabulator} from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

import { axiosService } from '@/_services/account.axios';
import Wait from '@/components/Wait';
import TitreH2 from '@/components/UI/titres/TitreH2';



const AddListeDiffusion = () => {

    const [initialValue, setInitialValues] = useState({     //flag pour affichage boutons après reponse API
        flagButton: false
    })

//initialisation du tableau////////////////////////////////
    useEffect(() => {
        if(initialValue.flagButton === false){

            let dataTab={
                adherents:'',
                listeDiffusion:''
            };
 
            const options = {
                values: {
                    domaine:'mail',
                    action:'getListeEmailsValides',
                    mailValide: true

                },
                OKData: (data) => {     //action callback déclanchée si tout est OK 
                    setInitialValues({flagButton:true});
                    dataTab.adherents= data;
                    afficheTableau(dataTab);
                },
                errorIdent: (data)=>{
                    console.log(data)
                }
            }

            //lancement requète API
            axiosService.postAxios(options);
             return;
        }
        },[])
    
    // tableau des adhérents ///////////////////////////////////////

    const afficheTableau = (data)=>{
        console.log(data.adherents)
        const listeAdherents = new Tabulator('#listeAdherents',{
            
            data:data.adherents,
            height: 300,
            layout:"fitColumns",
            
            columns:[
            {title: 'idAdherent', field: 'idAdherent', visible: false},
            {title:"Nom", field:"nom",width:120},
            {title:"Pr&eacute;nom", field:"prenom",width:120},
            {title:'Email', field:'email',width:300},
            ]

        });

        listeAdherents.on('rowClick', function(e,row){
            data= row.getData()
            emailListe.addRow({idAdherent:data.idAdherent,nom:data.nom,prenom:data.prenom,email:data.email})
            emailListe.setSort('nom','asc');
            row.delete();
        })


        const emailListe = new Tabulator("#emailsListe", {
            data: data.destinataires,
            height:300,
            layout:"fitColumns",
            columns:[
                {title: 'idAdherent', field: 'idAdherent', visible: false},
                {title:"Nom", field:"nom", width:120},
                {title: "Prénom", field: "prenom",width:120},
                {title: "email", field: "email", width:300},
            ], 
        });     

        emailListe.on('rowClick', function(e,row){
            data= row.getData()
            console.log(data)
            listeAdherents.addRow({idAdherent:data.idAdherent,nom:data.nom,prenom:data.prenom,email:data.email})
            listeAdherents.setSort('nom','asc');
            row.delete();
        })

        document.getElementById('majListe').addEventListener('click',function(){
            const libelleListe= document.getElementById('libelleListe').value;
            emailListe.selectRow();
            const destinataires= emailListe.getSelectedData();
            const options= {
                values: {
                    domaine: 'mail',
                    action: 'creerListe',
                    libelleListe: libelleListe,
                    destinataires: destinataires
                },
                OKData : (data)=>{
                    window.location.href= '/AR/majListeDiffusion';
                }
            }
            console.log(options)
            axiosService.postAxios(options);
       })

    }

    const divStyle={
        padding:'50px',
        margin:'20px'
        }
               

    return (
        <>
            {
                !initialValue.flagButton &&
                <Wait />
            }
                { initialValue.flagButton &&
                        <div style={divStyle}>
                            <TitreH2 titre='Ajouter une liste de diffusion' />
                            <div className='row'>
                                <span className='col-5 offset-1 fst-italic fs-6 text-info'>Click sur la ligne pour ajouter </span>
                                <span className='col-5 offset-1 fs-6 fst-italic text-info'>Click sur la ligne pour retirer</span>
                            </div>
                            <div className='row'>
                                <label for="libelleListe" className='col-2 offset-7 mb-3' >Nom de la liste</label>
                                <input name='libelleListe' id='libelleListe' className='col-3 mb-3' />
                            </div>
                            <div className='row'>
                                <div className='col-5 offset-1' id='listeAdherents' />
                                <div className='col-5 offset-1' id='emailsListe' />
                            </div>
                            <div className='row'>
                                <button className='col-2 offset-7 mt-5 button'  id='majListe'>Creer la liste</button>
                            </div>
                        </div>
                }

        </>
    );
};

export default AddListeDiffusion