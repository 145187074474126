import React, { useState} from 'react';

import { axiosService } from '@/_services/account.axios';
import Wait from '@/components/Wait';
import { accountService } from '../../../_services/account.service';

const FormAdh = (props) => {

    const formik= props.formik.formik;
    const flagMailValide = props.formik.flagMailValide;
    const statutMail = props.formik.statutMail;

    const [initialValues, setInitialValues] = useState({    
        flagVerif: false,               // une vérification est en cours
        ...props.formik.formik.initialValues
    })

    const [selectProfils,setSelectProfils] = useState({...props.formik.selectProfils});
 
    //vérification de l'existance de l'adresse mail et de l'absence de double emploi
    const emailBlur= (e)=>{
        formik.handleBlur(e);

        //supprimer l'ancien message d'anomalie
        let span = document.getElementById("message");
        span.firstChild && span.firstChild.remove(); 

        if(!formik.errors.email && e.target.value){

            flagMailValide.current= false;
            setInitialValues({
                ...initialValues,
                flagVerif: true,
            });
            console.log(accountService.isLogged())

            const espace= accountService.isLogged() ? 'prive' : 'public';

            const options = {
                values: {
                domaine: 'front',
                action: 'verifEmail',
                espace: espace,
                email: e.target.value,
                idAdherent: formik.initialValues.idAdherent,
                },
                OKData: () =>{
                    flagMailValide.current= true;
                    setInitialValues({
                        ...initialValues,
                        flagVerif: false,
                        emailValide: true,
                    });
                    formik.values.emailValide= true
                },
                errorData: data =>{
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);  
                    flagMailValide.current= false; 
                    statutMail.current= data.statut;
                    setInitialValues({
                        ...initialValues,
                        flagVerif: false,
                        emailValide: false,
                    });
                    formik.values.emailValide= false;

                }
            }

            axiosService.postAxios(options);        
        }
    }

    //formatage nom
    const nomBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.nom && e.target.value){
            formik.values.nom= e.target.value.toUpperCase();
        }
    }

    //Formatage n° de téléphone nn nn nn nn nn
    const telephoneBlur= (e)=>{
        formik.handleBlur(e);
        if(!formik.errors.telephone && e.target.value){
            let telin= e.target.value
            console.log(telin)
            let tel= telin.replace(/\s+/g, '');
            console.log(tel);
            formik.values.telephone= tel.substr(0,2) + ' '+ tel.substr(2,2)+' '+tel.substr(4,2)+' '+tel.substr(6,2)+' '+tel.substr(8,2);
        }
    }

    //validation adresse mail
    const checkEmailValideChange = (e)=>{
        formik.handleChange(e);
        formik.values.emailValide= e.target.value
        setInitialValues({
            ...initialValues,
            emailValide:true
        })
        flagMailValide.current = true;

    }

    //enregistrer sans valider
    const checkEmailInvalideChange = (e)=>{
        formik.handleChange(e);
        e= document.getElementById('valider')
        e.removeAttribute('disabled');
        e.classList.remove('btn-light')
    }

    return (
        <div id='adhesion' className='col-6 offset-2'>
            <form data-form-type="other" className='mb-4' onSubmit={formik.handleSubmit}>
                    <div className='row mb-2 col-10 offset-2 '>
                        <span className='text-danger ms-1 h4 strong' id='message' name='message'></span>
                    </div>
                    <div  id='valide' className='col-10 offset-2 mb-3'>
                        {
                           !initialValues.emailValide && accountService.isLogged() &&
                            <>
                                <label className='ms-3 text-danger'for='checkEmailValide'>Valider l'adresse mail</label>
                                <input className='ms-1' type='checkbox'  name='checkEmailValide' id='checkEmailValide'
                                onChange={checkEmailValideChange}
                                value={1}
                                onBlur={formik.handleBlur}
                                />
                                <label className='ms-3 text-danger'for='checkEmailInvalide'>Enregister sans valider l'adresse mail</label>
                                <input className='ms-1' type='checkbox'  name='checkEmailInvalide' id='checkEmailInvalide'
                                onChange={checkEmailInvalideChange}
                                value={1}
                                onBlur={formik.handleBlur}
                                />
                            </>
                        }
                    
                        {
                            initialValues.flagVerif &&
                            <div className='col-10 offset-6 ' style={{color: '#5f56c5'}}>
                                <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
                                <h4>Vérification mail en attente</h4>
                            </div>
                        }
                    </div>
                    <div className="mb-2">
                        <label for="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="Votre email"
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={emailBlur}
                        />
                        {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="nom" className="form-label">Nom</label>
                        <input type="text" className="form-control"  id="nom" placeholder="Votre nom"
                            name='nom'
                            onChange={formik.handleChange}
                            value={formik.values.nom}
                            onBlur={nomBlur}
                        />
                        {formik.touched.nom && formik.errors.nom && <span className='text-danger ms-1'>{formik.errors.nom}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="prenom" className="form-label">Prenom</label>
                        <input type="prenom" className="form-control" id="prenom" placeholder="Votre prenom"
                            name='prenom'
                            onChange={formik.handleChange}
                            value={formik.values.prenom}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.prenom && formik.errors.prenom && <span className='text-danger ms-1'>{formik.errors.prenom}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="dateNaissance" className="form-label">Date de naissance</label>
                        <input type="date" className="form-control" id="dateNaissance" placeholder="Votre dateNaissance"
                            name='dateNaissance'
                            onChange={formik.handleChange}
                            value={formik.values.dateNaissance}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.dateNaissance && formik.errors.dateNaissance && <span className='text-danger ms-1'>{formik.errors.dateNaissance}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="telephone" className="form-label">Téléphone</label>
                        <input type="text" className="form-control" id="telephone" placeholder="format nnnnnnnnn sans espace"
                            name='telephone'
                            onChange={formik.handleChange}
                            value={formik.values.telephone}
                            onBlur={telephoneBlur}
                        />
                        {formik.touched.telephone && formik.errors.telephone && <span className='text-danger ms-1'>{formik.errors.telephone}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="adresse" className="form-label">Adresse</label>
                        <input type="adresse" className="form-control" id="adresse" placeholder="Votre adresse"
                            onChange={formik.handleChange}
                            value={formik.values.adresse}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.adresse && formik.errors.adresse && <span className='text-danger ms-1'>{formik.errors.adresse}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="complements" className="form-label">complements</label>
                        <input type="complements" className="form-control" id="complements" 
                            name='complements'
                            onChange={formik.handleChange}
                            value={formik.values.complements}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.complements && formik.errors.complements && <span className='text-danger ms-1'>{formik.errors.complements}</span>}
                    </div>

                    <div className="mb-2">
                        <label for="codePostal" className="form-label">Code postal</label>
                        <input type="codePostal" className="form-control col-2" id="codePostal" placeholder="Code postal"aria-describedby="emailHelp"
                            name='codePostal'
                            onChange={formik.handleChange}
                            value={formik.values.codePostal}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.codePostal && formik.errors.codePostal && <span className='text-danger ms-1'>{formik.errors.codePostal}</span>}
                    </div>
                    <div className="mb-2">
                        <label for="ville" className="form-label">ville</label>
                        <input type="ville" className="form-control" id="ville" placeholder="Ville"aria-describedby="emailHelp"
                            name='ville'
                            onChange={formik.handleChange}
                            value={formik.values.ville}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.ville && formik.errors.ville && <span className='text-danger ms-1'>{formik.errors.ville}</span>}
                    </div>
                    {
                        selectProfils.current.OKMajProfil &&
                        <div className='mb-2'>
                            <label for='idProfil' className='form-label'>Profil</label>
                            <select name='idProfil' id='idprofil' className='' 
                                onChange={formik.handleChange}
                                value={formik.values.idProfil}
                                onBlur={formik.handleBlur}
                            >
                                {
                                    selectProfils.current.profils.map((profil)=>{
                                        return(
                                            <option value={profil.idProfil} selected={profil.idProfil===formik.values.idProfil ? 'selected' : ''}>
                                                {profil.profil}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    <div className='row'>
                        <button id='valider' type='submit' className="button btn-light col-3 mt-3" onClick={formik.handleSubmit}
                            disabled={!flagMailValide.current ? 'disabled' : '' }
                        >
                        Enregister</button>
                    </div>
            </form>
            
        </div>
    );
};

export default FormAdh;