import React, { useEffect, useRef, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik} from 'formik';
import * as Yup from 'yup';


import { axiosService } from '@/_services/account.axios';
import TitreH2 from '@/components/UI/titres/TitreH2';
import FormAdh from './FormAdh';
import Wait from '@/components/Wait';

const MajAdhesion = () => {

    const navigate = useNavigate()

    const flagMailValide= useRef(true);    //flag pour la validation de l'email
    const statutMail= useRef();              // staut du retour de la verif mail
    const libBouton= 'Modifier';

    // Récupération ID adhérent   
    const { idAdherent } = useParams()

    //l'adhérent peut-il modifier le profif si oui liste des profils
    let selectProfils= useRef()
    
    //valeurs par défaut pour le formulaire
    const [initialValues, setInitialValues] = useState( {      
            situation:'demandee',
            idProfil: 10,    // visiteur
        })

    const [flag,setFlag] = useState(false)

        // Récupération de l'utilisateur à l'affichage
    useEffect(() => {     
        if (flag=== false) {

            const options= {
                values:{
                    domaine:'front',
                    action:'getMajAdherent',
                    idAdherent:idAdherent,    
                },
                OKData: (data)=>{
                    const initials={...initialValues,...data.form}

                    flagMailValide.current= data.form.emailValide== 1 ? true : false; //conversion en booleen

                    setInitialValues(initials);
                    selectProfils.current= {
                        OKMajProfil: data.OKMajProfil,
                        profils: data.profils
                    }
                    setFlag(true);

                }
                }
            axiosService.postAxios(options);
        }

        return;
    }, [])
     
    
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize:true,

        validationSchema: Yup.object().shape({
            nom: Yup.string()
                .required('Nom obligatoire'),
            prenom: Yup.string()
                .required('Prénom obligatoire'),
            email: Yup.string().email('Adresse mail erronée')
                .required('Email obligatiore'),
            dateNaissance: Yup.date('Date naissance erronnée'),
                // .required('Date naissance obligatoire'),
            adresse: Yup.string(),
            complements: Yup.string().notRequired(),
           codePostal:Yup.string()
                .required('Code postal obligatoire')
                .max(5,'Code postal invalide')
                .matches('[0-9 ]{5}','Code postal invalide')
                ,
            ville: Yup.string()
                .required('Ville obligatoire')
        }),

        //MAJ BDD par requète API
        onSubmit: values => {
            values.domaine = 'front';    //Domaine API
            values.action = 'setMajAdherent';       // action API
            values.emailValide = flagMailValide.current ? 1 : 0;    //conversion en num

            const options = {
                values: values,
                errorData: (data) => {
                    let span = document.getElementById("message");
                    span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);          
                },
                OKData: (data) => {
                    //action si OK
                    navigate('/AR/majAdhesion');
                }
            }

            axiosService.postAxios(options);
        }
      });

    return (
        <>
            {
                flag ?
                    <>
                        <TitreH2 titre= 'Enregister une adhésion' />
                        <FormAdh formik={{formik,flagMailValide,statutMail,libBouton,selectProfils}} />
                    </>
                :
                    <Wait />

             }
        </>

    );
};
export default MajAdhesion;