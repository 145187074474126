import React from 'react';
import {Routes, Route} from "react-router-dom";

import Adherents from './Adherents';
import Adhesion from '@/pages/public/adhesions/Adhesion';
import MajAdhesion from '@/pages/public/adhesions/MajAdhesion';
import ValidAdhesion from '@/pages/prive/adherents/ValidAdhesion';
import Error from '@/components/errors/Error';
import { accountService } from '../../../_services/account.service';

const AdherentsRouter = ()=>{
    return (
        <Routes>
            {
                accountService.isLogged &&
                <>
                    <Route path='' element={<Adherents />}/>
                    <Route path='adhesion' element={<Adhesion />} />
                    <Route path='adherents' element={<Adherents />}/>
                    <Route path='validAdhesion/:idAdherent/:nom/:prenom' element={<ValidAdhesion />} />
                    <Route path='majAdhesion/:idAdherent' element={<MajAdhesion />} />
                    <Route path='*' element={<Error type='404'>la page n'existe pas</Error>} />
                </>
            }
            {
                !accountService.isLogged &&
                <>
                    <Route path='adhesion' element={<Adherents />}/>
                    <Route path='*' element={<Error type='404'>la page n'existe pas</Error>} />
                </>
            }
        </Routes>
    )
};

export default AdherentsRouter;
