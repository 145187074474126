import React from 'react';

const style={
    margin:'20px'
}

const Wait = () => {
    return (
        <div className='row'>
            <div className='col-3 offset-5 d-flex align-items-center justify-content-center' style={{color: '#5f56c5', height:'200px'}}>
                <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                <p> Loading...</p>
            </div>
        </div>
);
};

export default Wait;