import React from 'react';

const TitreH2 = (props) => {
    return (
        <div className='row'>
        <h2 className='text-center mb-2 p-3'>{props.titre}</h2>
    </div>
);
};

export default TitreH2;
