import React from 'react';
import {Routes, Route} from "react-router-dom";

import Error from '@/components/errors/Error';

import Mailing from '@/pages/prive/mail/Mailing';
import ListeMails from '@/pages/prive/mail/ListeMails';
import ListesDiffusion from '@/pages/prive/mail/ListesDiffusion';
import MajListeDiffusion from '@/pages/prive/mail/MajListeDiffusion';
import AddListeDiffusion from '@/pages/prive/mail/AddListeDiffusion';

const MailRouter = ()=>{
    return (
        <Routes>
          <Route path='' element={<ListeMails />} />
          <Route path='mailing' element={<Mailing />} />
          <Route path='listeMails' element={<ListeMails />} />
          <Route path='listesDiffusion' element={<ListesDiffusion />} />
          <Route path='addListeDiffusion' element={<AddListeDiffusion />} />
          <Route path='majListeDiffusion/:idListe/:libelleListe' element={<MajListeDiffusion />} />
          <Route path='*' element={<Error type='404'>la page n'existe pas</Error>} />
        </Routes>
    )
}

export default MailRouter;