import React from 'react';
import { useFormik} from 'formik';
import * as Yup from 'yup';


import { axiosService } from '@/_services/account.axios';
import TitreH2 from '@/components/UI/titres/TitreH2';
import { accountService } from '@/_services/account.service';


const Login = () => {
    
    
    //formulaire Formik

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },

        
        // validation formulaire
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Adresse mail erronée')
            .required('Email obligatiore'),
            password: Yup.string()
            .required('Mot de passe obligatoire')
        }),

        //MAJ BDD par requète API
        onSubmit: values => {
            values.domaine = 'front';    //Domaine API
            values.action = 'login';       // action API

            const options = {
                values: values,
                OKData: (data) => {
                    //action si OK
                    console.log(data);
                    window.location.href= '/';
                },
                errorIdent: (data) =>{
                    console.log(data)
                    let span = document.getElementById("message");
                    span.firstChild && span.firstChild.remove(); //supprimer l'ancien message d'anomalie
                    let txt = document.createTextNode(data.message);
                    span.appendChild(txt);          
                }
            }

            axiosService.postAxios(options);
        }
    })

    //si loggé routage vers la page adhérent
    if(accountService.isLogged()){
        window.location.href= '/adherents';
        return;
    }

    return (
        <>
            <TitreH2 titre='Saisissez vos indentifiants' />

            <form data-form-type="other">
                  <div className='mb-2'>
                    <span className='text-danger ms-1' id='message' name='message'></span>
                  </div>
                  <div className="mb-2">
                      <label for="email" className="col-1 offset-2">Email</label>
                      <input type="email" className="col-3" id="email" placeholder="Votre email"aria-describedby="emailHelp"
                          name='email'
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && <span className='text-danger ms-1'>{formik.errors.email}</span>}
                  </div>
                  <div className="mb-2">
                      <label for="password" className="col-1 offset-2 mt-4">Password</label>
                      <input type="password" className="col-2" id="password" placeholder="votre mot de passe" autocomplete="off" data-dashlane-rid="a298491225ccf496" data-kwimpalastatus="alive" data-kwimpalaid="1691182305139-2" data-form-type="password"
                          name='password'
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          onBlur={formik.handleBlur}
                      />
                      {formik.touched.password && formik.errors.password && <span className='text-danger ms-1'>{formik.errors.password}</span>}
                  </div>
                  <div>
                    <button type="submit" className="col-1 offset-3 button mt-3" onClick={formik.handleSubmit}>Envoyez</button>
                  </div>
                  <div className="col-2 offset-3 mt-3">
                    <a href="/login/mdpOublie">Mot de passe oublié</a>
                  </div>
            </form>            
        </>
    );
};

export default Login;