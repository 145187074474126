import React from 'react';

import './accueil.css'

const Accueil = () => {
    return (
        <div className='container'>
        </div>
    );
};

export default Accueil;